<template>
	<div v-if="hasPermission">
		<ContentRunLevel />
	</div>
</template>

<script>
// @ is an alias to /src
import ContentRunLevel from '@/components/content/content-acc/ContentRunLevel.vue'
import { getPermissions } from '../components/util/liblist'

export default {
	name: 'LedgerAccounts',
	components: {
		ContentRunLevel,
	},
	data() {
		return {
			'hasPermission': false, 
		}
	},
	mounted() {
		if (getPermissions('accounting.reportext.runlevel')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
