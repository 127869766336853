<template>
    <div>
        <button :type="type" class="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
            {{title}}
        </button>
    </div>
</template>

<script>
    export default {
        name:'Btn',
        props: {
            title: {
                type: String,
                default: 'Name'
            },
            type:{
                type: String,
                default: 'submit'
            }
        },
        data() {
            return {
            }
        },
    }
</script>

<style>

</style>