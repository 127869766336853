<template>
	<div class="container px-6 mx-auto grid dark:bg-gray-900">
		<h2
			class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
		>
			Nivel de ejecucion
		</h2>
		<div class="grid grid-cols-2 gap-2">
			<div class="items-center text-sm w-full flex block-inline">
				<div class="w-96">
					<Multiselect 
					v-model="auxiliary" 
					tag-placeholder="Add" 
					placeholder="Cuenta" 
					label="name" track-by="code" 
					:options="objAccountSelect" 
					:multiple="false" 
					:taggable="true" 
					@tag="addTag"
				></Multiselect>
				</div>
				<!-- <label class="px-2 py-3 w-1/12 text-center ">Desde</label>
				<input type="date" v-model="fromDate" placeholder="Incio" class="ml-2 border-2 border-gray-300 rounded py-2 px-2 w-29" >
				<label class="px-2 py-3 w-1/12 text-center ">Hasta</label>
				<input type="date" v-model="toDate" placeholder="Fin" class="ml-2 border-2 border-gray-300 rounded py-2 px-2 w-29">				 -->
				<button @click="getButget()" class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
					Buscar
				</button>
			</div>
			<div class="text-left font-bold">
				{{auxiliaryName}}
			</div>
			
		</div>
		<div class="w-full overflow-hidden rounded-lg shadow-xs ">
				<div class="w-full overflow-x-auto">
					<table class="w-full whitespace-no-wrap">
						<thead>
							<tr
								class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
							>
								<th class="px-2 py-3 w-1/12">Cuenta</th>
								<th class="px-2 py-3 ">Nombre</th>
								<th class="px-2 py-3 w-1/12 text-center ">Presupuesto</th>
								<th class="px-2 py-3 w-1/12 text-center ">Ejecutado</th>
								<th class="px-2 py-3 w-1/12 text-center ">Variacion</th>
							</tr>
						</thead>
						<tbody
							class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
						>
							<tr v-for="(item, index) in objButget" :key="index" class="text-gray-700 dark:text-gray-400">
								
								<td class="px-1 py-3 text-xs text-left">
									{{item.accountCode}}
								</td>
								<td class="px-1 py-3 text-xs text-left">
									{{item.description}}
								</td>
								<td class="px-1 py-3 text-xs">
									{{formatNumber(item.available_budget, ",", "$", true) }}
								</td>
								<td class="px-1 py-3 text-xs">
									{{formatNumber(item.executed_amount, ",", "$", true)}}
								</td>
								<td class="px-1 py-3 text-xs">
									{{
										item.available_budget-item.executed_amount > 0
										? formatNumber(item.available_budget-item.executed_amount, ",", "$", true)
										: `( ${formatNumber(item.available_budget-item.executed_amount, ",", "$", true)} )`
									}}							
								</td>
								
							</tr>

						</tbody>
					</table>
				</div>
			</div>
	</div>
</template>

<script>

import Axios from 'axios'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import Btn from '@/components/util/btnBasic.vue'
	export default {
		name: 'NumberAccTransactions',
		components: {
			Multiselect,
			Btn,
		},
		props: {},
		data() {
			return {
				auxiliary: '',
				fromDate:'',
				toDate:'',
				URL: process.env.VUE_APP_URL_ACC,
				URLAdm: process.env.VUE_APP_URL,
				objAccountSelect:[],
				objButget:[],
				account:'',
				auxiliaryName:'',
			}
		},
		computed: {
			...mapState(['user', 'accTemp', 'accTransResul','access_token']),
		},
		mounted() {
			this.getAccTransaction()

			// this.getButget()
		},
		
		methods: {
			addTag (newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.transaction.push(tag)
            },
			formatNumber(number, separator = ',', symbol='$', showSymbol = false){
				number = parseFloat(number)
				number = number.toFixed(2)
				if (separator == ',') {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1.$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				} else {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1,$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				}
			},
			async getAccTransaction() {
				const URL = `${this.URLAdm}v1/contract`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                        Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					companyId: this.user.companyId
				}
				
				// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					console.log(rsAccount)
					this.objAccountSelect = rsAccount.data.map(item => {
						// return { text: item.accountName, value: item.id}
						return { name: item.contractNumber + ' - ' + item.siteAddress, code: item.contractNumber}
					})
					console.log(this.objAccountSelect)
				
			},

			async getButget() {
				const URL = `${this.URL}showAccountAndAuxiliaryExecutionLevel`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					company_id: this.user.companyId,
					auxiliary: this.auxiliary.code,
					fromDate: this.fromDate,
					toDate: this.toDate
				}
				this.auxiliaryName = this.auxiliary.name
				console.log(params)

				try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					console.log(rsAccount.data.data)
					if (rsAccount.data.data.length > 0) {
						this.objButget = rsAccount.data.data;
					} else {
						alert(`No se encontron registros para el auxiliar ${this.auxiliary.code}`)
					}
				} catch (error) {
					
				}
					

			},
		},
		
		watch: {

		},
			
	}
</script>

<style >

</style>