
// funcion para obtener y validar los permisos que estan en el local storage
// a la funcion se le indica un permiso, y ella verifica si un usuario cuenta con ese permiso
let user = window.localStorage.getItem('user')
const getPermissions = (permission) => {
    let listPesmissions =  JSON.parse(user)
    for (let valuePermission of listPesmissions.permissions) {
            if(valuePermission.name.indexOf(permission) !== -1){
                return true;
            }
        }
    }


    
module.exports = {
    getPermissions,
}